import { ThemeDefinition, createVuetify } from 'vuetify';
import * as components from 'vuetify/components'
import LuxonAdapter from "@date-io/luxon"
import 'vuetify/styles'

const luxon = new LuxonAdapter({ locale: "nz" });

const main: ThemeDefinition = {
  dark: false,
  colors: {
    surfBlue: '#00336B',
    surfLightBlue: '#0095F6',
    surfYellow: '#FFDA00',
    surfGrey: '#666666'
  },
}

const vuetify = createVuetify({
  date: {
    adapter: luxon,
  },
  components: {
    ...components,
  },
  theme: {
    defaultTheme: 'main',
    themes: {
      main
    }
  }
})

export default vuetify;
