<script setup lang="ts">
import { useInterfaceStore } from "@/store/interface";
// import NewIncidentModal from "@/components/UI/NewIncidentModal.vue";
import { RoleType } from "@/models/types";
import { RouteLocationRaw, useRouter } from "vue-router";
import { useAuthStore } from "@/store/auth";
import { useBreadCrumbStore } from "@/store/breadcrumb";
import { computed, ref, onMounted } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import slsLogo from "@/assets/sls-logo.png";
import { DateTime } from "luxon";
import { useFeedBackStore } from "@/store/feedback";
import { isTraining } from "@/firebase";
import { updateTrainingLoginTime } from "@/helpers/RecordDailyLogin"
import * as Sentry from "@sentry/vue";

const authStore = useAuthStore();
const breadCrumbStore = useBreadCrumbStore();
const interfaceStore = useInterfaceStore();
const router = useRouter();
const feedbackStore = useFeedBackStore();

const display = useDisplay();

onMounted(async () => {
  if (isTraining) {
    const user = await authStore.getUser
    if (user) {
      updateTrainingLoginTime(user)
    }
  }
})

const drawer = ref(false);
const feedbackDialog = ref(false);
const feedback = ref('');

const goHome = () => {
  router.push({ path: "/map" });
}

const menuItems = computed<MenuItem[]>(() => {
  let items: MenuItem[] = [
    {
      name: 'Map',
      icon: 'mdi-map',
      route: { name: 'map' },
      roles: [RoleType.SLSClubAdmin, RoleType.SLSAdmin, RoleType.DutyOfficer, RoleType.CoastGuard],
      training: true
    },
    {
      name: 'Communications',
      icon: 'mdi-radio-handheld',
      route: { name: 'communications' },
      roles: [RoleType.SLSAdmin, RoleType.DutyOfficer, RoleType.CoastGuard],
      training: true
    },
    {
      name: 'Communications Archive',
      icon: 'mdi-archive',
      route: {
        name: 'communications-archive',
        params: {
          date: localDate.value,
        },
      },
      roles: [RoleType.SLSAdmin, RoleType.DutyOfficer, RoleType.CoastGuard],
      training: true
    },
    {
      name: 'Patrol Stats',
      icon: 'mdi-view-list-outline',
      route: {
        name: 'patrol',
        params: {
          date: localDate.value,
        },
      },
      roles: [RoleType.SLSAdmin, RoleType.DutyOfficer, RoleType.CoastGuard],
      training: true
    },
    {
      name: 'System Log',
      icon: 'mdi-table-clock',
      route: {
        name: 'systemlog',
        params: {
          date: localDate.value,
        },
      },
      roles: [RoleType.SLSAdmin, RoleType.DutyOfficer],
      training: true
    },
    {
      name: 'Season Schedule',
      icon: 'mdi-calendar-blank',
      route: { name: 'schedule.select' },
      roles: [RoleType.SLSAdmin, RoleType.SLSClubAdmin, RoleType.DutyOfficer],
      training: false
    },
    {
      name: 'Asset Management',
      icon: 'mdi-clipboard-edit-outline',
      route: { name: 'asset-management' },
      roles: [RoleType.SLSClubAdmin, RoleType.SLSAdmin],
      training: false
    },
    {
      name: 'Rip Prediction NZ',
      icon: 'mdi-waves',
      route: {
        name: 'rippredictionnz',
        params: {
          date: localDate.value,
        },
      },
      roles: [RoleType.SLSAdmin, RoleType.WeatherUser, RoleType.DutyOfficer],
      training: false
    },
    {
      name: 'Admin',
      icon: 'mdi-cog',
      route: {
        name: 'admin',
      },
      roles: [RoleType.SLSClubAdmin, RoleType.SLSAdmin],
      training: true
    },
    {
      name: 'Report',
      icon: 'mdi-file-chart',
      route: {
        name: 'report',
      },
      roles: [RoleType.SLSClubAdmin, RoleType.SLSAdmin, RoleType.DutyOfficer],
      training: true
    },
    {
      name: 'Rip Prediction (Old)',
      icon: 'mdi-waves',
      route: {
        name: 'ripprediction',
        params: {
          date: localDate.value,
        },
      },
      roles: [RoleType.SLSAdmin, RoleType.WeatherUser, RoleType.DutyOfficer],
      training: false
    },

  ];
  if (isTraining) {
    items = items.filter((item) => item.training);
    // Adds training overview to nav bar (Pushes to existing array for efficiency)
    items.push({
      name: 'Training Overview',
      icon: 'mdi-account-eye-outline',
      route: {
        name: 'training-overview',
        params: {
          date: localDate.value,
        },
      },
      roles: [RoleType.SLSAdmin, RoleType.SLSClubAdmin],
      training: true
    },)
  }

  if (isDeveloper.value) {
    return items;
  } else if (role.value === RoleType.SLSAdmin) {
    return items.filter((item) => item.roles.includes(RoleType.SLSAdmin));
  } else if (role.value === RoleType.DutyOfficer) {
    return items.filter((item) => item.roles.includes(RoleType.DutyOfficer));
  } else if (role.value === RoleType.SLSClubAdmin) {
    return items.filter((item) => item.roles.includes(RoleType.SLSClubAdmin));
  } else if (role.value === RoleType.WeatherUser) {
    return items.filter((item) => item.roles.includes(RoleType.WeatherUser));
  } else if (role.value === RoleType.CoastGuard) {
    return items.filter((item) => item.roles.includes(RoleType.CoastGuard));
  } else {
    return [];
  }
});

const authUser = computed(() => {
  return authStore.user;
})

const isDeveloper = computed(() => {
  return authUser.value?.developer ?? false
})

const role = computed<RoleType | undefined>(() => {
  return authStore.user?.roleType;
})

const canAccessAdmin = computed(() => {
  const role = authStore.getUser?.roleType
  return role === RoleType.SLSAdmin || role === RoleType.SLSClubAdmin
})

const isSLSAdmin = computed(() => {
  const role = authStore.getUser?.roleType
  return role === RoleType.SLSAdmin;
})

const isWeatherOnly = computed(() => {
  const role = authStore.getUser?.roleType
  return role === RoleType.WeatherUser;
})

const signUserOut = () => {
  // Todo: Connect to auth store
  authStore.signOut().then((result) => {
    if (result) {
      //router.push({ path: "/" });
      location.reload()
    }
  });
}

const localDate = computed(() => {
  return DateTime.fromJSDate(new Date).toFormat("yyyy-LL-dd")
})

const openFeedbackDialog = () => {
  feedbackDialog.value = true;
}
const sendFeedback = () => {
  if (feedback.value === '') {
    interfaceStore.NotifyError('Feedback cannot be empty')
    return
  }
  feedbackStore.createFeedback({ message: feedback.value })
  feedback.value = ''
  feedbackDialog.value = false
}
const appVersion = computed(() => {
  return authStore.appVersion;
})
const user = computed(() => {
  if (authStore.user) {
    return authStore.user;
  }
  return {
    firstName: "",
    lastName: "",
    roleType: "",
  };
})

const breadCrumbs = computed(() => {
  const breadCrumbs = breadCrumbStore.breadCrumb;
  const bcArray = [];
  if (breadCrumbs.incident !== null) {
    bcArray.push(breadCrumbs.incident);
  }
  if (breadCrumbs.patient !== null) {
    bcArray.push(breadCrumbs.patient);
  }
  if (breadCrumbs.vital !== null) {
    bcArray.push(breadCrumbs.vital);
  }
  return bcArray;
})

interface MenuItem {
  route: RouteLocationRaw;
  icon: string;
  name: string;
  roles: RoleType[];
  training: boolean;
}

const testSentry = () => {
  console.log('Testing sentry');
  const dateMills = DateTime.fromJSDate(new Date()).toMillis();
  Sentry.captureException(`Testing Direct Sentry catch: ${dateMills}`);
  throw new Error(`Testing throw new error: ${dateMills}`);
}
</script>

<template>
  <v-app-bar class="no-print" color="white">
    <v-dialog v-model="feedbackDialog" width="500">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" variant="text">
          <v-icon color="surfBlue" left>mdi-message-text</v-icon>
          <span class="surfBlue--text d-none d-sm-flex">FEEDBACK</span>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="title">FEEDBACK</span>
        </v-card-title>
        <v-card-text>
          <span>Please provide feedback or any issues you are having with this
            application or would like to make a suggestion</span>
          <v-textarea v-model="feedback" outline></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn variant="text" color="red" @click="feedbackDialog = false">CANCEL</v-btn>
          <v-spacer></v-spacer>
          <v-btn variant="text" color="surfBlue" @click="sendFeedback">SUBMIT</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <img :src="slsLogo" contain height="40" />
    <v-spacer></v-spacer>
    <v-breadcrumbs :items="breadCrumbs" v-if="display.mdAndUp">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-spacer></v-spacer>
    <!-- <v-text-field outline single-line append-icon="search" height="20"></v-text-field> -->
    <v-spacer></v-spacer>
    <!-- <NewIncidentModal v-if="user.roleType === 'SLSAdmin'" /> -->
    <v-btn variant="text" @click="drawer = !drawer">
      <v-icon color="surfBlue" left>mdi-menu</v-icon>
      <span class="surfBlue--text d-none d-sm-flex">Menu</span>
    </v-btn>
  </v-app-bar>
  <v-navigation-drawer v-model="drawer" temporary location="right">
    <v-sheet color="grey-lighten-3" height="100">
      <v-container pa-3>
        <span class="title">{{ user?.firstName ?? '' }} {{ user?.lastName ?? '' }}</span>
        <br />
        <span class="subheading">{{ user?.roleType ?? '' }}</span>
        <br />
        <span class="overline grey--text">Version: {{ appVersion ?? '' }}</span>
      </v-container>
    </v-sheet>
    <v-list density="comfortable" nav>
      <v-divider></v-divider>
      <v-list-item v-for="item in menuItems" :key="item.name" :to="item.route">
        <template v-slot:prepend>
          <v-icon :icon="item.icon"></v-icon>
        </template>
        <template v-slot:title>
          <div>{{ item.name }}</div>
        </template>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item @click="signUserOut">
        <template v-slot:prepend>
          <v-icon icon="mdi-logout"></v-icon>
        </template>
        <template v-slot:title>
          <div>Sign out</div>
        </template>
      </v-list-item>
      <div v-if="isDeveloper">
        <v-divider></v-divider>
        <span class="pa-2 mt-2">DEVELOPER ONLY</span>
        <v-list-item to="/feedback">
          <template v-slot:prepend>
            <v-icon icon="mdi-chat"></v-icon>
          </template>
          <template v-slot:title>
            <div>View Feedback</div>
          </template>
        </v-list-item>
        <v-list-item @click="testSentry()">
          <template v-slot:prepend>
            <v-icon icon="mdi-target"></v-icon>
          </template>
          <template v-slot:title>
            <div>Test Sentry</div>
          </template>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>
